//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState} from "vuex";
import utils from "@/utils";

import CertificatesModal from "@/components/documents/modals/CertificatesModal";
import SignModal from "@/components/documents/modals/SignModal";
import SignPepModal from "@/components/documents/modals/SignPepModal";
import SignIcon from "@/components/icons/SignIcon";
import DescriptionSign from "@/components/documents/table/DescriptionSign";

export default {
  name: "SignCard",
  components: {CertificatesModal, SignModal, SignPepModal, SignIcon, DescriptionSign},
  props: {
    document: Object,
    documentType: String,
    isDocumentPage: Boolean,
    getDocuments: Function,
    changeDocument: Function,
    forceRender: Function,
    signRules: Object,
    ecSignRules: Array,
    documentDate: String,
    isExternal: Boolean,
  },
  data() {
    return {
      showSignModal: false,
      showSignPepModal: false,
      isCertificatesModal: false,
      tooltipObject: { 
        content: this.userGlobal ? this.$t('sign.sign_not_allowed_by_rules') : this.$t('sign.sign_not_allowed_by_global_user'), 
        classes: ['show'], 
        placement: this.isDocumentPage ? 'bottom' : 'top',
        trigger: 'hover', 
        offset: 20,
        disabled: true,
      },
      ecSignRulesForDocument: null,
    }
  },
  created(){
    if (this.document.fromExternalEducation) {
      const companyType = this.document.isIAmResponsible ? 'CLIENT_ID' : 'EC_ID';
      const companyId = this.document.isIAmResponsible ? this.document.studentCompanyId : this.document.ecCompanyId;
      this.ecSignRulesForDocument = this.ecSignRules.find((ecRules) => ecRules[companyType] == companyId);
    }
    this.markDocumentSignPolicy();
  },
  updated() {
    this.markDocumentSignPolicy();
  },
  methods: {
    markDocumentSignPolicy() {
      const signAllowed = this.canSign && this.canBeDs;
      const signAllowedAndNotSigned = this.canSign && !this.isSignedOnPaper && !this.isSigned;
      this.tooltipObject.disabled = signAllowed;
      this.document.signAllowed = signAllowedAndNotSigned && this.canBeDsSigned;
      const signRule = (this.ecSignRulesForDocument && this.ecSignRulesForDocument.RULES) || this.signRules;
      this.document.signOnDateOnly = signAllowedAndNotSigned && signRule[this.document.type] && signRule[this.document.type].UF_SIGN_ONLY_DOC_DATE && this.documentDate !== this.today();
    },
    showSignersModal() {
      this.showSignModal = true
      utils.addModalOpenClass()
    },
    hideSignersModal() {
      this.showSignModal = false
      utils.removeModalOpenClass()
    },
    showCertificatesModal() {
      if(!(this.canBeDs && this.canSign)) {
        return
      }
      this.isCertificatesModal = true
      utils.addModalOpenClass()
    },
    hideCertificatesModal() {
      this.isCertificatesModal = false
      if (!this.isExternal)
        utils.removeModalOpenClass()

    },
    downloadDoc() {
      if (this.document.id) {
        utils.downloadDocument(this.document.id, this.documentType)
      }
    },
    today() {
      let today = new Date(),
      dd = String(today.getDate()).padStart(2, '0'),
      mm = String(today.getMonth() + 1).padStart(2, '0'),
      yyyy = today.getFullYear();

      return dd + '.' + mm + '.' + yyyy;
    },
    setTooltipMessage(message) {
      this.tooltipObject.content = message;
    },
    signPep() {
      if(this.isCertificatesModal) 
        this.isCertificatesModal = false;
      this.showSignPepModal = true;
    },
    closePepModal(signed = false, back = false) {
      this.showSignPepModal = false;
      if(back) 
        this.isCertificatesModal = true;
      if(signed)
        this.getDocuments(this.$store.state.documentPageLimit, this.$store.state.documentPageNumber);
      if(!back && !this.isExternal) 
        utils.removeModalOpenClass();
    },
  },
  computed: {
    currentSigner() {
      const current = this.document.signers.find(signer => signer.workerId == (this.currentStudent.ID || this.worker.id))

      return current || ''
    },
    signedDate() {
      return this.isSigned && this.currentSigner && this.currentSigner.dsProcessedAt || ''
    },
    isSignedOnPaper() {
      return this.document.processed && !this.document.dsProcessed
    },
    isSigned() {
      return this.currentSigner && this.currentSigner.dsProcessedAt
    },
    signType() {
      const signType = this.currentSigner && this.currentSigner.sign && this.currentSigner.sign.signatureType;
      let signTypeText;
      switch (signType) {
        case "ds":
          signTypeText = this.$t('sign.ds');
          break;
        case "pep": 
          signTypeText = this.$t('sign.pep');
          break;
        default:
          signTypeText = ''
      }
      return signTypeText;
    },
    canBeDsSigned() {
      if(!this.ecSignRulesForDocument && Object.getOwnPropertyDescriptor(this.signRules, this.document.type) === undefined) {
        return false;
      }

      if (this.ecSignRulesForDocument) {
        if (Object.getOwnPropertyDescriptor(this.ecSignRulesForDocument.RULES, this.document.type) === undefined)
          return false;
      }

      let allow = false;
      const docType = this.document.type;
      if (this.document.isIAmResponsible) {
        if (this.ecSignRulesForDocument) {
          allow = this.ecSignRulesForDocument.RULES[docType].UF_SIGN_PEP_RESP;
        } else {
          allow = this.signRules[docType].UF_SIGN_PEP_RESP;
        }
      } else {
        if (this.ecSignRulesForDocument) {
          allow = this.ecSignRulesForDocument.RULES[docType].UF_SIGN_PEP;
        } else {
          allow = this.signRules[docType].UF_SIGN_PEP;
        }
      }

      if (allow === false) {
        return allow;
      }
      return true;
    },
    canBeDs() {
      if(!this.ecSignRulesForDocument && Object.getOwnPropertyDescriptor(this.signRules, this.document.type) === undefined) {
        return false;
      }

      if (this.ecSignRulesForDocument) {
        if (Object.getOwnPropertyDescriptor(this.ecSignRulesForDocument.RULES, this.document.type) === undefined)
          return false;
      }

      let allow = false;
      const docType = this.document.type;
      if (this.document.isIAmResponsible) {
        if (this.ecSignRulesForDocument) {
          allow = this.ecSignRulesForDocument.RULES[docType].UF_SIGN_NEP_RESP || this.ecSignRulesForDocument.RULES[docType].UF_SIGN_KEP_RESP || this.ecSignRulesForDocument.RULES[docType].UF_SIGN_PEP_RESP;
        } else {
          allow = this.signRules[docType].UF_SIGN_NEP_RESP || this.signRules[docType].UF_SIGN_KEP_RESP || this.signRules[docType].UF_SIGN_PEP_RESP;
        }
      } else {
        if (this.ecSignRulesForDocument) {
          allow = this.ecSignRulesForDocument.RULES[docType].UF_SIGN_NEP || this.ecSignRulesForDocument.RULES[docType].UF_SIGN_KEP || this.ecSignRulesForDocument.RULES[docType].UF_SIGN_PEP;
        } else {
          allow = this.signRules[docType].UF_SIGN_NEP || this.signRules[docType].UF_SIGN_KEP || this.signRules[docType].UF_SIGN_PEP;
        }
      }
      
      if (allow === false 
            || (this.signRules[docType] && this.signRules[docType].UF_SIGN_ONLY_DOC_DATE === false)
            || (this.ecSignRulesForDocument && this.ecSignRulesForDocument.RULES && this.ecSignRulesForDocument.RULES[docType] && this.ecSignRulesForDocument.RULES[docType].UF_SIGN_ONLY_DOC_DATE === false)) {
          return allow;
      }

      if (this.documentDate !== this.today()) {
          this.setTooltipMessage(this.$t('sign.sing_allowed_only_date', {date: this.documentDate}));
          return false;
      }
      return true;
    },
    canSign() {
      return this.hasAccessToSign && !this.userGlobal;
    },
    ...mapState([
      'hasAccessToSign',
      'currentStudent',
      'worker',
      'userGlobal',
    ])
  },
}
