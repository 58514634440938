//
//
//
//
//
//

export default {
  name: "Loader",
  props: {
    isLogin: Boolean,
    isTraining: Boolean
  },
  computed: {
    getLoaderStyle() {
      if (this.isLogin) {
        return 'stand-preloader-img-login';
      }

      if (this.isTraining) {
        return 'training-loader';
      }

      return '';
    }
  }
}
