//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from "../../../app.config"
import Loader from "@/components/main/Loader"
import TabLinks from "@/components/auth/TabLinks"
import StateMachine from "../../stateMachine";
import api from "@/api/api";
import {mapActions, mapMutations, mapState} from "vuex";
import utils from "@/utils";
import VueCountdown from '@chenfengyuan/vue-countdown';
import Login from "@/components/auth/fields/Login";
import PinCode from "@/components/auth/fields/Pincode";

const STATE_INIT = 'init'
const STATE_PHONE_SET = 'phone_set'
const STATE_CODE_SET = 'code_set'
const STATE_PIN_SET = 'pin_set'
const STATE_PASSWORD_SET = 'password_set'

export default {
  name: "ForgotPassword",
  components: {PinCode, Login, TabLinks, Loader, VueCountdown},
  data: function () {
    return {
      error: '',
      config,
      showLoader: false,
      title: this.$i18n.t('auth.title'),
      loginError: false,
      phone: '',
      code: '',
      pin: '',
      pinConfirm: '',
      hash: '',
      pinLength: 6,

      state: new StateMachine(STATE_INIT,
          {
            [STATE_PHONE_SET]: this.setPhone,
            [STATE_INIT]: async () => {
            },
            [STATE_CODE_SET]: this.setCode,
            [STATE_PIN_SET]: this.setPin,
            [STATE_PASSWORD_SET]: this.setPassword,
          }
      ),

      maxCodeTime: 60 * 1000,
      timerValue: 0,
      showResendCode: false,
    }
  },
  methods: {
    setPhoneValue(val) {
      this.phone = val
    },
    setPinValue(value) {
      this.pin = value
    },
    setPinConfirmValue(value) {
      this.pinConfirm = value
    },
    setTimer() {
      this.timerValue = this.maxCodeTime
    },
    endTimer() {
      this.timerValue = 0
    },
    padStart(value, length, chars) {
      return utils.padStart(value, length, chars)
    },
    cleanErrors() {
      this.error = ''
    },
    onCodeTimerEnd() {
      this.timerValue = 0
      this.showResendCode = true
    },
    setLoginError(error) {
      this.loginError = error
    },
    getLoginError() {
      return this.loginError
    },
    setPasswordError(error) {
      this.passwordError = error
    },
    getPasswordError() {
      return this.passwordError
    },
    checkResponse(response, onError = null) {
      if (response.data && response.data.error) {
        response.data.errorStr ? this.setError(response.data.errorStr) : this.setError(this.$t('errors.undefined_error'))

        if (onError && (typeof onError === 'function')) {
          onError()
        }

        throw new Error('error')
      }

      if (!response.data || !response.data.result || (typeof response.data.result !== 'object') || Object.keys(response.data.result) <= 0) {
        this.setError(this.$t('errors.undefined_error'))

        if (onError && (typeof onError === 'function')) {
          onError()
        }

        throw new Error('error')
      }
    },
    async setPhone() {
      this.cleanErrors()

      if (!this.phone) {
        this.setError(this.$t(this.config.variables && this.config.variables.hidePhoneAuth ? 'forgot.email_not_filled' : 'forgot.email_or_phone_not_filled'))
        await this.setState(STATE_INIT, true)

        throw  new Error('phone empty')
      }

      this.showLoader = true

      const request = api.resetPassword({
        login: this.phone
      })

      await request.then(response => {
        this.checkResponse(response, () => {
          this.setState(STATE_INIT, true)
        })

        this.setTimer()

        if (response.data && response.data.result && response.data.result.hash) {
          this.hash = response.data.result.hash
        } else {
          this.setError(this.$t('errors.undefined_error'))

          this.endTimer()

          throw  new Error('hash is empty')
        }        
        if (config.variables && config.variables.passwordPolicyEnable)
          this.setState(STATE_PASSWORD_SET, true);
      }).finally(() => {
        this.showLoader = false
      })
    },
    async setPassword() {
      console.log("setPassword")
      if (!this.pin) {
        this.setError(this.$t('forgot.enter_password'));
        await this.setState(STATE_PASSWORD_SET, true);
        throw  new Error('password empty');
      }
      const { valid, errorMessage } = utils.handleValidatePasswordForm(this.pin, config, this.$t.bind(this), this.pinConfirm)
      if (!valid) {
        this.setError(errorMessage);
        await this.setState(STATE_PASSWORD_SET, true);
        throw  new Error('password error');
      }
      const request = api.setNewPin({
        pin: this.pin,
        repeat_pin: this.pinConfirm,
        hash: this.hash,
      })

      this.showLoader = true

      await request.then(async response => {
        this.checkResponse(response, () => {
          this.setState(STATE_PASSWORD_SET, true)
        })

        if (response.data && response.data.result && response.data.result.token) {
          this.setToken(response.data.result.token);
          await this.getUserAndStudents()
          await this.getStudentInfo()
          localStorage.edu_app_token = response.data.result.token;

          this.$router.history.push({
            name: 'courses'
          })
        } else {
          this.setError(this.$t('errors.undefined_error'))

          throw  new Error('token is empty')
        }
      }).finally(() => {
        this.showLoader = false;
      })
    },
    async setInit(e) {
      e.preventDefault()
    },
    async setCode() {
      this.cleanErrors()

      if (!this.code) {
        this.setError(this.$t('forgot.enter_code'))
        await this.setState(STATE_PHONE_SET, true)

        throw  new Error('code empty')
      }

      const request = api.confirmReset({
        code: this.code,
        hash: this.hash,
      })

      this.showLoader = true

      await request.then(response => {
        this.checkResponse(response, () => {
          this.setState(STATE_PHONE_SET, true)
        })

        if (!response.data && response.data.result && response.data.result.result) {
          this.setError(this.$t('errors.undefined_error'))

          throw  new Error('hash is empty')
        }
      }).finally(() => {
        this.showLoader = false;
      })
    },
    async setPin() {
      this.cleanErrors();
      if (!this.pin) {
        this.setError(this.$t('forgot.enter_pin'))
        await this.setState(STATE_CODE_SET, true)

        throw  new Error('pin empty')
      }

      if (this.pin.length !== this.pinLength) {
        this.setError(this.$t('forgot.pin_length_error'))
        await this.setState(STATE_CODE_SET, true)

        throw  new Error('pin length error')
      }

      if (this.pin !== this.pinConfirm) {
        this.setError(this.$t('forgot.pin_error'))
        await this.setState(STATE_CODE_SET, true)

        throw  new Error('pin not confirmed')
      }

      const request = api.setNewPin({
        pin: this.pin,
        repeat_pin: this.pinConfirm,
        hash: this.hash,
      })

      this.showLoader = true

      await request.then(async response => {
        this.checkResponse(response, () => {
          this.setState(STATE_CODE_SET, true)
        })

        if (response.data && response.data.result && response.data.result.token) {
          this.setToken(response.data.result.token);
          await this.getUserAndStudents()
          await this.getStudentInfo()
          localStorage.edu_app_token = response.data.result.token;

          this.$router.history.push({
            name: 'courses'
          })
        } else {
          this.setError(this.$t('errors.undefined_error'))

          throw  new Error('token is empty')
        }
      }).finally(() => {
        this.showLoader = false
      })
    },
    repeatSend(e) {
      e.preventDefault()
      this.showResendCode = false
      this.code = ""
      this.setState(STATE_PHONE_SET)
    },
    goBack() {
      this.$router.history.push({
        name: 'login'
      })
    },
    setError(error) {
      this.error = error
    },
    async setState(state, withoutCallback = false) {
      await this.state.transition(state, withoutCallback)
    },
    setInitState() {
      this.state.transition(STATE_INIT)
    },
    onCodeKeypress($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
    onCodeInput($event) {
      $event.target.value = $event.target.value.replace(/\D+/g, '')
    },
    ...mapMutations([
      'setToken'
    ]),
    ...mapActions([
      'getUserAndStudents',
      'getStudentInfo',
    ])
  },

  computed: {
    isInitState() {
      return this.state.is(STATE_INIT)
    },
    isPhoneState() {
      return this.state.is(STATE_PHONE_SET)
    },
    isCodeState() {
      return this.state.is(STATE_CODE_SET)
    },
    isPasswordState() {
      return this.state.is(STATE_PASSWORD_SET);
    },
    confirmCode: {
      get() {
        return this.code
      },
      set(value) {
        this.code = value.replaceAll(/\D+/ig, '')
      }
    },
    message() {
      if (this.isInitState) {
        return this.$t(this.config.variables && this.config.variables.hidePhoneAuth ? 'forgot.auth_enter_email' : 'forgot.auth_enter_phone')
      }

      if (this.isPhoneState) {
        return this.$t('forgot.code_message') + ': ' + this.phone + ', ' + this.$t('forgot.enter_code_below')
      }

      if (this.isCodeState) {
        return this.$t('forgot.your_login') + ': ' + this.phone + ' ' + this.$t('forgot.confirmed') + '<br>' + this.$t('forgot.pin_message')
      }

      return ''
    },
    changeText() {
      if (utils.validateEmail(this.phone)) {
        return this.$t('forgot.change_email')
      }

      return this.$t('forgot.change_phone')
    },
    buttons() {
      return [
        {
          label: this.$t('forgot.send_phone'),
          click: async (e) => {
            e.preventDefault();
            if (this.loginError) {              
              this.setError(this.$t("forgot.email_or_phone_invalid"))
              return
            }
            this.setState(STATE_PHONE_SET)
          },
          if: this.isInitState,
          disabled: false,
          class: {disabled: this.loginError},
        },
        {
          label: this.$t('forgot.send_code'),
          click: async (e) => {
            e.preventDefault()
            
            if (!this.code) {
              this.setError(this.$t("errors.code_not_filled"))
              return;
            }

            if (!utils.validateCode(this.code)) {
              this.setError(this.$t("errors.invalid_code"))
              return
            }

            if (config.variables && config.variables.passwordPolicyEnable) {
              await this.setState(STATE_CODE_SET);
              this.setState(STATE_PASSWORD_SET, true);
            }
            else
              this.setState(STATE_CODE_SET);
          },
          if: this.isPhoneState,
          disabled: false,
          class: {disabled: !utils.validateCode(this.code)},
        },
        {
          label: this.$t('forgot.send_pin'),
          click: async (e) => {
            e.preventDefault();

            if (!this.pin) {
              this.setError(this.$t("errors.pin_code_not_filled"));
              return;
            }

            if (!utils.validateCode(this.pin)) {
              this.setError(this.$t("errors.invalid_pin_code"));
              return;
            }

            if (!this.pinConfirm) {
              this.setError(this.$t("errors.confirm_pin_code_not_filled"));
              return;
            }

            if (!utils.validateCode(this.pinConfirm)) {
              this.setError(this.$t("errors.invalid_confirm_pin_code"));
              return;
            }

            if (this.pin !== this.pinConfirm) {
                this.setError(this.$t("errors.pin_code_not_matched"));
                return;
            }

            this.setState(STATE_PIN_SET);
          },
          if: this.isCodeState,
          disabled: false,
          class: {disabled: !utils.validatePin(this.pin, this.pinConfirm)},
        },
        {
          label: this.$t('forgot.send_pin'),
          click: async (e) => {
            e.preventDefault();
            const { valid, errorMessage } = utils.handleValidatePasswordForm(this.pin, config, this.$t.bind(this), this.pinConfirm);
            if (!valid) {
              this.setError(errorMessage);
              return;
            }
            this.setState(STATE_PASSWORD_SET);
          },
          if: this.isPasswordState,
          disabled: false,
          class: {disabled: !utils.handleValidatePasswordForm(this.pin, config, this.$t.bind(this), this.pinConfirm).valid},
        },
      ]
    },
    buttonsForState() {
      return this.buttons.filter(button => button.if)
    },
    ...mapState([
      'authMethod',
      'idAuthMethod',
      'phoneAuthMethod',
    ]),
  }
}
